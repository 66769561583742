<template>
  <div>
    <central-loader v-if="isLoading"></central-loader>
    <Appbar @close="$router.push({ name: 'home' })" :close="true" :menu="false"></Appbar>
    <v-main>
      <v-container class="pa-0 mt-4">
        <v-list class="mb-15" three-line>
          <v-list-item
            v-for="review in reviews"
            :key="review.id"
          >
            <v-list-item-avatar>
              <v-icon v-if="!review.avatar" x-large>mdi-account</v-icon>
              <v-img v-else :src="avatarUrl(review.avatar)"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="text-body-2 font-weight-6 pl-1">
                {{ review.lastName }}, {{ review.firstName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-row>
                  <v-rating 
                    class="ml-4"
                    color="primary"
                    empty-icon="mdi-star-outline"
                    half-icon="mdi-star-half-full"
                    half-increments
                    dense
                    readonly
                    size="12"
                    :value="review.rating"
                  ></v-rating>
                  <small class="last-update green--text text--darken-1 ml-1">{{ lastUpdate((review.updatedAt || review.createdAt)) }}</small>
                </v-row>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-caption pl-1" v-html="review.review"></v-list-item-subtitle>
              <v-list-item-subtitle 
                class="text-caption pl-1" 
                v-if="review.replies"
              >
                <v-card 
                  tile 
                  flat
                  color="grey lighten-2" 
                  class="chatbox pa-2 mt-4"
                  v-for="(reply, index) in review.replies"
                  :key="'reply-card-' + index"
                >
                  <v-card-title class="text-caption primary--text font-weight-6 p-0">
                    <v-row class="p-0 m-0">
                      {{ reply.replyFrom }}
                      <small class="last-update green--text text--darken-1 ml-2 mt-0">{{ lastUpdate(reply.replyAt) }}</small>
                    </v-row>
                  </v-card-title>
                  <v-card-text class="review-reply p-0">
                    {{ reply.reply }}
                  </v-card-text>
                </v-card>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-bottom-navigation fixed background-color="primary">
          <v-btn
            :disabled="isLoading"
            class="white-txt-btn align-self-center"
            block
            @click="dialogReviewForm = true; isSubmitted = false;"
          >Write Review</v-btn>
        </v-bottom-navigation>
        <v-dialog v-model="dialogReviewForm" width="400px">
          <ValidationObserver ref="form">
            <form @submit.prevent="submitReview()">
              <v-card v-if="!isSubmitted">
                <v-card-title class="justify-center text-subtitle-1 font-weight-6 pb-0">
                  Please help us to serve you better
                </v-card-title>
                <v-card-text class="text-center pb-3">
                  <v-row>
                    <v-col cols="12" class="px-0">
                      <ValidationProvider
                        name="Rating"
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <v-rating 
                          v-model="rating"
                          color="primary"
                          background-color="primary"
                          empty-icon="mdi-star-outline"
                          half-icon="mdi-star-half-full"
                          dense
                          size="48"
                          :error-messages="errors"
                          :success="valid"
                        ></v-rating>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" class="px-5">
                      <ValidationProvider
                        name="Review"
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <v-textarea
                          v-model="review"
                          class="text-caption"
                          outlined
                          auto-grow
                          hide-details
                          rows="3"
                          placeholder="How's our food quality and service?"
                          :error-messages="errors"
                          :success="valid"
                        ></v-textarea>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" class="px-5 py-0" v-if="errors.length > 0">
                      <v-list>
                        <v-list-item
                          v-for="(error, index) in errors"
                          :key="'error-' + index"
                          class="p-0"
                          dense
                        >
                          <v-list-item-icon>
                            <v-icon color="#D32F2F">mdi-alert-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="text-body-2 font-weight-6 red--text">{{ error }}</v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="12" class="px-5">
                      <v-btn
                        :disabled="isLoading"
                        color="primary"
                        block
                        @click="submitReview()"
                      >Submit</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card v-else>
                <v-card-title class="text-h5">
                  
                </v-card-title>
                <v-card-text class="px-5 text-body-2 text-justify font-weight-5">
                  Thank you for your time and review. We can serve you better with those feedbacks.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    class="text-body-2 text-capitalize font-weight-6 mr-1 mb-1"
                    small
                    text
                    outlined
                    @click="closeForm()"
                  >
                    Okay !
                  </v-btn>
                </v-card-actions>
              </v-card>
            </form>
          </ValidationObserver>
        </v-dialog>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import Appbar from '@/components/partials/Appbar';
import CentralLoader from '@/components/Loaders/CentralLoader';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

export default {
  name: 'MerchantReviews',
  components: {
    CentralLoader,
    ValidationObserver, 
    ValidationProvider, 
    Appbar,
  },
  data: function() {
    return {
      cdnUrl: process.env.VUE_APP_CDN_URL,
      legacyCdnUrl: process.env.VUE_APP_LEGACY_CDN_URL,
      dialogReviewForm: false,
      rating: 0,
      review: '',
      errors: [],
      isLoading: false,
      isSubmitted: false,
    };
  },
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
      reviews: (state) => state.reviews,
    }),
  },
  methods: {
    ...mapActions('merchant', ['getMerchantReviews', 'createMerchantReview']),
    avatarUrl(avatar) {
      return avatar?.startsWith('http') ? avatar : (`${this.legacyCdnUrl}/upload/${avatar}`);
    },
    lastUpdate(lastUpdate) {
      return dayjs(lastUpdate)?.fromNow();
    },
    submitReview() {
      this.$refs.form.validate().then(async (success) => {
        if (!success) {
          if (this.rating === 0) {
            this.errors.push('Please input your <Star Rating>');
          }

          if (this.review.trim() === '') {
            this.errors.push('Please input your <Review>');
          }

          this.clearErrors();

          return;
        }

        // Confirm order
        this.isLoading = true;
        await this.createMerchantReview({
          merchantId: this.profile.merchant_id,
          data: {
            rating: this.rating,
            review: this.review,
          }
        });
        this.isLoading = false;

        this.rating = 0;
        this.review = '';
        this.isSubmitted = true;
      });
    },
    clearErrors() {
      setTimeout(() => this.errors = [], 5000);
    },
    closeForm() {
      this.dialogReviewForm = false;
      setTimeout(() => this.isSubmitted = false, 500);
    }
  },
  async mounted() {
    dayjs.extend(relativeTime);

    if (!this.profile?.merchant_id) {
      this.$router.push({ name: 'home' });
    }

    await this.getMerchantReviews(this.profile.merchant_id);
  },
};
</script>

<style scoped>
.last-update {
  font-size: 10px;
  margin-top: 2px;
}
.review-reply {
  font-size: 10px;
}
.chatbox:first-child {
  margin-top: 10px !important;
}
.chatbox::before {
  position: absolute;
  content: "";
  top: 0;
  left: 10px;
  transform: translateY(-100%);
  width: 6px; 
  height: 8px; 
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #e0e0e0;
}
.alert {
  position: fixed;
  z-index: 999;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
}
@media screen and (min-width: 1024px) {
  .alert {
    width: 374px !important;
  }
}
</style>
